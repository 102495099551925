p{
    display:block;
}
.home{
    color:white;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    margin-top:10vh;
    height:  90vh;
}
.title{
    font-size: 5vh;
}
.aboutme{
    display: flex;
    flex-direction: row;
    height:50vh;
    width:50%; 
    font-size:3vh;
    text-align:left;
}

.workexperience{
    height: 50vh;
    color:white;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
    margin-bottom: 10vh;
}
.projects{
    height: 70vh;
    color:white;
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
}
.resume{
    height:110vh;
    width:100%;
    color:white;
    background:linear-gradient(#f06553,#fff474);
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    align-items: center;
}

a:link{
    text-decoration: none;
}
.img-border{
  border: double .5vh transparent;
  border-radius: 50%;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left,white,white);
  background-origin: border-box;
  background-clip: content-box, border-box;
  width:50%;
  height:70%;
}

@media screen and (max-width: 850px) {
    .home{
        color:white;
        display: flex;
        height:130vh;
        flex-direction: column;
        justify-content:flex-start;
        align-items: center;
        white-space: pre-line;
        margin-top:13vh;
    }
    .aboutme{
        display: flex;
        flex-direction: column;
        height:70vh;
        width:80%;
        font-size:2.6vh;
        text-align:left;
        align-items: center;
    }
    .img-border{
        border: double .5vh transparent;
        border-radius: 50%;
        background-image: linear-gradient(white, white), radial-gradient(circle at top left,white,white);
        background-origin: border-box;
        background-clip: content-box, border-box;
        width:50%;
        height:50%;
      }
    .title{
        vertical-align:center;
        font-size:3vh;
    }
    .workexperience{
        height:80vh;
        width:100vw;
        color:white;
        display: flex;
        flex-direction: column;
        justify-content:flex-start;
        align-items: center;
        margin-bottom: 0vh;
    }
    .projects{
        height: 80vh;
        width: 100vw;
        color:white;
        display: flex;
        flex-direction: column;
        justify-content:flex-start;
        align-items: center;
    }
    .resume{
        height:6vh;
        width:100vw;
        padding-left:0vw;
        color:white;
        display: flex;
        flex-direction: column;
        justify-content:flex-start;
        align-items: center;
        overflow: hidden;
    }
    .pdf{
        display:none;
    }
    .img-border{
        border: double 4px transparent;
        border-radius: 50%;
        background-image: linear-gradient(white, white), radial-gradient(circle at top left,white,white);
        background-origin: border-box;
        background-clip: content-box, border-box;
        width:50%;
        height:30%;
        object-fit: cover;
        
      }
}

