* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}
body{
  width: 100%;
  height: 100%;
  margin: 0;
}

.background-container{
  background-color: black;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

