.footer {
    color:white;
    background: black;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position:sticky;
    font-size:3vh;

  }
  .email{
     margin-top: 2vh;
     margin-right:2vw;  
  }
  .github{
    margin-right:2vw; 
  }
  .info{
    margin-top:0vh;
    display:flex;
    flex-direction: row;
    font-size:2vh;
  }
  .emailButton{
    padding:1vh;
    font-size:2vh;
    font-weight: 600;
    color:white;
    background-color: #551a8b;
    text-decoration: none;
  }
  .emailButton:hover{
    background-color:#00FFDC;
    color:black;
  }
  i.fas,
  i.fab {
    color:#551a8b;
  }
  i.fas:hover,i.fab:hover{
    color:#00FFDC;
  }
 
  a:link{
    color:white;
    text-decoration: none;
}

@media screen and (max-width: 812px){
  .footer {
    color:white;
    background: black;
    width:100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position:sticky;
    font-size:3vh;

  }
  .email{
     margin-top: 2vh;
     margin-right:2vw;  
  }
  .github{
    margin-right:2vw; 
  }
  .info{
    margin-top:0vh;
    display:flex;
    flex-direction: row;
    font-size:2vh;
  }
  .emailButton{
    padding:1vh;
    font-size:2vh;
    font-weight: 600;
    color:white;
    background-color: #551a8b;
    text-decoration: none;
  }
  .emailButton:hover{
    background-color:#00FFDC;
    color:black;
  }
  i.fas,
  i.fab {
    color:#551a8b;
  }
  i.fas:hover,i.fab:hover{
    color:#00FFDC;
  }
 
  a:link{
    color:white;
    text-decoration: none;
}
}