.tabs {
  width:50vw;
  height:70vh;
  padding:2vh 3vh;
  overflow:hidden;
}
.tab-content{
    padding-left: 1vw;
}
.content-header{
    padding-bottom: 2vh;
}
.tab-content ul li{
  font-size:1em;
}
li{
    padding-bottom: .5vh;
}
.tabs .tab-header {
  float:left;
  width:13vw;
  height:100%;
  border-right:.3vh solid #ccc;
  padding:2vh 0vh;
}
.tabs .tab-header > div {
  height:6vh;
  line-height:5vh;
  font-size:2vh;
  font-weight:600;
  color:#888;
  cursor:pointer;
}
.tabs .tab-header > div:hover,
.tabs .tab-header > div.active {
  color:#00FFDC;
}

.tabs .tab-content {
  position:relative;
  text-align: left;
  height:50vh;
  width:30vw;
  overflow:auto;
}
.tabs .tab-content > div > i {
  display:inline-block;
  width:20vw;
  height:10vh;
  background:#555;
  text-align:left;
  line-height:5vh;
  border-radius:50%;
}
.tabs .tab-content > div {
  position:absolute;
  text-align:left;
  padding:2vh 1vh;
  top:-200%;
  transition:all 500ms ease-in-out;
}
.tabs .tab-content > div.active {
  top:0px;
}
.workTitle{
  font-size:2.5vh;
}
@media screen and (max-width: 850px) {
  .tabs {
    width:100vw;
    height:100vh;
    padding:0px 1vh;
  }
  
  .content-header{
      padding-bottom: 2vh;
  }
  .tab-content li{
      padding-bottom: .5vh;
  }
  .tabs .tab-header {
    float:left;
    width:100vw;
    height:20vh;
    border-right:0px;
    padding:0vh 0px;
  }
  .tab-header{
    width:100vw;
    display:flex;
    flex-direction: row;
  }
  .tabs .tab-header > div {
    height:12vh;
    line-height:50px;
    font-size:.9rem;
    font-weight:600;
    color:#888;
    padding-right: 3vw;
    cursor:pointer;
    border-bottom: 1px solid #fff;
  }
  .tabs .tab-header > div:hover,
  .tabs .tab-header > div.active {
    color:#00FFDC;
  }
  
  .tabs .tab-content {
    position:relative;
    width: 100vw;
    text-align: center;
    height:100%;
    overflow:hidden;
  }
  .tabs .tab-content > div > i {
    display:inline-block;
    width:50px;
    height:50px;
    background:#555;
    text-align:left;
    line-height:50px;
    border-radius:50%;
    
  }
  .tabs .tab-content > div {
    position:absolute;
    text-align:left;
    padding:0px 20px;
    top:-100%;
    transition:all 500ms ease-in-out;
  }
  .tabs .tab-content > div.active {
    top:0px;
  }
   
  .tabs .tab-indicator {
    position:absolute;
    width:4px;
    height:50px;
    left:198px;
    top:80px;
    transition:all 500ms ease-in-out;
  }
}
@media screen and (min-height:950px) and (max-height:1030px){
  .tabs {
    width:100vw;
    height:100vh;
    padding:0px 4vh;
  }
  
  .content-header{
      padding-bottom: 2vh;
  }
  .tab-content li{
      padding-bottom: .5vh;
  }
  .tabs .tab-header {
    float:left;
    width:100vw;
    height:12vh;
    border-right:0px;
    padding:0vh 0px;
  }
  .tab-header{
    width:100vw;
    display:flex;
    flex-direction: row;
  }
  .tabs .tab-header > div {
    height:6vh;
    line-height:50px;
    font-size:2vh;
    font-weight:600;
    color:#888;
    padding-right: 3vw;
    cursor:pointer;
    border-bottom: 1px solid #fff;
  }
  .tabs .tab-header > div:hover,
  .tabs .tab-header > div.active {
    color:#00FFDC;
  }
  
  .tabs .tab-content {
    position:relative;
    width: 100vw;
    text-align: center;
    height:100%;
    overflow:hidden;
  }
  .tabs .tab-content > div > i {
    display:inline-block;
    width:50px;
    height:50px;
    background:#555;
    text-align:left;
    line-height:50px;
    border-radius:50%;
    
  }
  .tabs .tab-content > div {
    position:absolute;
    text-align:left;
    padding:0px 20px;
    top:-100%;
    transition:all 500ms ease-in-out;
  }
  .tabs .tab-content > div.active {
    top:0px;
  }
   
  .tabs .tab-indicator {
    position:absolute;
    width:4px;
    height:50px;
    left:198px;
    top:80px;
    transition:all 500ms ease-in-out;
  }
}

@media screen and (max-height:670px){
  .tabs .tab-header > div {
    height:15vh;
    line-height:50px;
    font-size:.9rem;
    font-weight:600;
    color:#888;
    padding-right: 3vw;
    cursor:pointer;
    border-bottom: 1px solid #fff;
  }
}