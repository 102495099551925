.cards{
    display:flex;
    flex-direction: column;
    color:white;
    justify-content: center;
    align-items: center;
    width:80%;
}
.cardContent{
    display: flex;
    flex-direction: row;
}
.knightContent{
    display: flex;
    flex-direction: row;
    width:70%
}
.cardTitle{
    font-size:4vh;
}
.cardDetails{
    font-size: 2.5vh;
    padding-left: 5vh;
}
.tags{
    font-size: 1.7vh;
    font-family:monospace;
    display: inline;
    list-style: none;
    padding:.3vh;
    border-radius:10%;
    background-color:#E6FFFF;
    box-shadow: -.3vh .3vh #FB00FF;
    color:#1D0B76;
    margin-right: 2vh;
}
.tags:hover{
    box-shadow: -.3vh .3vh #00FFDC;
}
.rec.rec-arrow {
    color:white;
    border-style: solid white;
}
.rec.rec-arrow:hover {
    color:#00FFDC;
}
.rec.rec-arrow:disabled {
    visibility: hidden;
}
.KnightImg{
    width:350px;
    height:300px;
}
.CassetteNetImg{
    width:80vh;
    height:40vh;
}
.githubLink{
    font-size:3rem;
}
i.fas,
  i.fab {
    color:#551a8b;
  }
  i.fas:hover,i.fab:hover{
    color:#00FFDC;
  }

@media screen and (max-width:850px){
    .cards{
    display:flex;
    flex-direction: column;
    color:white;
    justify-content: center;
    align-items: center;
    width:80%;
}
.cardContent{
    display: flex;
    flex-direction: column;
    width:100%
}
.knightContent{
    display: flex;
    flex-direction: column;
    width:100%
}
.cardTitle{
    font-size:2vh;
}
.cardDetails{
    font-size: 1.6vh;
    padding-left: .5vh;
}
.tags{
    font-size: 1vh;
    font-family:monospace;
    display: inline;
    list-style: none;
    padding:.3vh;
    border-radius:10%;
    background-color:#E6FFFF;
    box-shadow: -.3vh .3vh #FB00FF;
    color:#1D0B76;
    margin-right: 1vh;
}
.tags:hover{
    box-shadow: -.3vh .3vh #00FFDC;
}
.rec.rec-arrow {
    color:white;
    border-style: solid white;
}
.rec.rec-arrow:hover {
    color:#00FFDC;
}
.rec.rec-arrow:disabled {
    visibility: hidden;
}
.KnightImg{
    width:50vw;
    height:20vh
}
.CassetteNetImg{
    width:65vw;
    height:20vh;
}
.githubLink{
    font-size:3rem;
}
i.fas,
  i.fab {
    color:#551a8b;
  }
  i.fas:hover,i.fab:hover{
    color:#00FFDC;
  }
}