.skills{
    color:white;
    display:flex;
    flex-direction:row;
}
.skills-title{
    font-size: 3vh;
    padding-bottom:1vh;
}
.skills-list, .list-item {
    display:block;
    justify-content: center;
    position: relative;
    padding-bottom: 1vh;
    padding-right:3vw;
  }
  .list-item{
      padding-left:3vw;
      font-size:2vh;
  }
  
.skills-list {
    list-style: none;
  }
.subskill-title{
    font-size: 2.3vh;
}
  
.skills-list .list-item:before{
     content: '';
     position: absolute;
     border-right:.2vh solid white;
     border-bottom:.2vh solid white;
     width:.8vh;
     height:.8vh;
     top: calc(50% - .3vh);
     left: 1.5vw;
     transform: translateY(-50%) rotate(-45deg);
}
@media screen and (max-width:850px){
    .skills{
        color:white;
        display:flex;
        flex-direction:row;
        justify-content: flex-end;
        padding-left: 5vw;
    }
    .skills-title{
        display: flex;
        justify-content: center;
        font-size: 3vh;
        padding-bottom:1vh;
        padding-left: 5vw;
    }
    .skills-list, .list-item {
        display:block;
        position: relative;
        padding-bottom: 1vh;
        padding-right:3vw;
      }
      .list-item{
        padding-left:3vw;
        font-size:2vh;
      }
      
    .skills-list {
        list-style: none;
      }
    .subskill-title{
        font-size: 2.3vh;
    }
      
    .skills-list .list-item:before{
         content: '';
         position: absolute;
         border-right:.2vh solid white;
         border-bottom:.2vh solid white;
         width:.8vh;
         height:.8vh;
         top: calc(50% - .3vh);
         left: 1.5vw;
         transform: translateY(-50%) translateX(-30%) rotate(-45deg);
    }
}