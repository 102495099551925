.navbar {
    background: black;
    height: 10vh;
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    padding-left: 10vw;
    top: 0;
    z-index: 999;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;;
    max-width: 100%;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    width:40%;
    cursor: pointer;
    text-decoration: none;
    font-size: 250%;
    display: flex;
    align-items: center;
  }
  .navbar-logo:hover{
    border-bottom: .2vh solid #fff;
    transition: all 0.2s ease-out;
  }
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: .5vw;
    list-style: none;
    text-align: center;
    width: 60vw;
    justify-content: end;
  }
  
  .nav-item {
    height: 7vh;
    font-size:2.5vh;
  }
  .nav-item > .active {
    border-bottom: .2vh solid white;
}
  
  .nav-links {
    color: #fff;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }
  
  .nav-links:hover {
    border-bottom: .2vh solid #fff;
    transition: all 1s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 850px) {
    .navbar{
    background: black;
    height: 80px;
    width:100%;
    display: flex;
    justify-content: center;
    position:fixed;
    align-items: center;
    font-size: 1.2rem;
    padding-left: 0;
    top: 0;
    right:0;
    z-index: 999;
    }
    
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 80vh;
      position: fixed;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background:black;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      color: black;
      border-radius: 0;
    }
  
    .navbar-logo {
      font-size: 2vh;
      position: fixed;
      top: 0;
      left: 0;
      transform: translate(25%, 50%);
      font-size: 1.5rem;
    }
  
    .menu-icon {
      display: block;
      position: fixed;
      top: 2vh;
      right:10vw;
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: black;
      transition: 250ms;
    }
  }
  
  @media screen and (max-height:650px){
    .navbar-logo{
      font-size:3.6vh;
    }
  }