#particles-js {
    background:linear-gradient(black,#000A83);
    height: 100vh;
    width:100%;
}

#particles-js-two{ 
    background:linear-gradient(#000A83,blue);
    height: 60vh;
    width:100%;
}
#particles-js-three{
    background:linear-gradient(blue,#f06553);
    height: 70vh;
    width:100%;
}
#particles-js-four{
    display: none;
}

@media screen and (max-width: 960px) {
    #particles-js {
        background:linear-gradient(black,#000A83);
        height: 143vh;
    }
    
    #particles-js-two{ 
        background:linear-gradient(#000A83,blue);
        height:80vh;
    }
    #particles-js-three{
        background:linear-gradient(blue,#f06553);
        height: 80vh;
    }
}